import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchJsonData } from "../utils";

const Signin = (props) => {
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const formData = JSON.stringify({
      email: e.target.email.value,
      password: e.target.password.value,
    })
    const { error, user } = await fetchJsonData("/users/login", formData);

    if (error) {
      document.querySelector("#messageBox").classList.remove("d-none");
      document.getElementById("message").innerHTML = error;
    } else {
      document.querySelector("#messageBox").classList.add("d-none");
      if (user.isAdmin) {
        navigate("/dashboard");
      } else {
        navigate("/");
      }
    }
  };

  return (
    <div className="container d-sm-flex justify-content-center">
      <form onSubmit={handleLogin} className="w-40">
        <div className="mb-3">
          <h1>Sign In</h1>
        </div>
        <div className={`mb-3 alert alert-danger d-none`} id="messageBox">
          <span id="message"></span>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="email">
            Email address
          </label>
          <input
            className="form-control"
            type="email"
            id="email"
            name="email"
            placeholder="Enter email"
            required
          ></input>
        </div>

        <div className="mb-3">
          <label className="form-label" htmlFor="password">
            Password
          </label>
          <input
            className="form-control"
            type="password"
            id="password"
            name="password"
            placeholder="Enter password"
            required
          ></input>
        </div>

        <div className="mb-3">
          <button className="btn btn-primary w-100" type="submit">
            Sign In
          </button>
        </div>

        <div className="mb-3">
          New user? <Link to={`/register`}>Create your account</Link>
        </div>
      </form>
    </div>
  );
};

export default Signin;
