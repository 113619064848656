import React from "react";

function HijriMonthForm(props) {
  return (
    <div className="d-sm-flex flex-column align-items-center justify-content-between mb-4">
      <h1 className="h3 mb-0 text-gray-800">New Profile</h1>
      <form onSubmit={props.handleSubmit}>
        <div className="mb-3">
          <label htmlFor="month" className="form-label">
            Hijri Month
          </label>
          <input type="tel" className="form-control" id="month" name="month" />
        </div>
        <div className="mb-3">
          <label htmlFor="year" className="form-label">
            Hijri Year
          </label>
          <input type="tel" className="form-control" id="year" name="year" />
        </div>
        <div className="mb-3 d-sm-flex justify-content-start align-items-center">
          <label htmlFor="is29Month">
            is 29 Month?
          </label>
          <input type="checkbox" className="ml-4 mb-2" id="is29Month" name="is29Month" />
        </div>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  );
}

export default HijriMonthForm;
