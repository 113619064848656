import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Events from "./Events";
import HijriMonths from "./HijriMonths";
import News from "./News";
import Profiles from "./Profiles";
import Projects from "./Projects";
import { SERVER_ADDRESS } from '../utils';

function Dashboard(props) {
  const navigate = useNavigate();
  const [collections, setCollections] = useState([]);
  const handleLogout = async () => {
    const res = await fetch(`${SERVER_ADDRESS}/users/logout`, {
      credentials: "include",
    });
    await res.json();
    navigate("/");
  };
  useEffect(() => {
    let mounted = true;
    async function onBoard() {
      const res = await fetch(`${SERVER_ADDRESS}/dashboard`, {
        credentials: "include",
      });
      const { data, error } = await res.json();
      if (error) {
        navigate("/");
      } else {
        if (mounted) {
          setCollections(data);
        }
      }
    }
    onBoard();
    return function cleanup() {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  return (
    <>
      <div id="wrapper">
        <Sidebar collections={collections} />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">
              <Routes>
                <Route path="/events" element={<Events />} />
                <Route path="/news" element={<News />} />
                <Route path="/profiles" element={<Profiles />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/hijrimonths" element={<HijriMonths />} />
              </Routes>
            </div>
          </div>
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>Copyright &copy; www.islamiccare.com.au 2022</span>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <div
        className="modal fade"
        id="logoutModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Ready to Leave?
              </h5>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              Select "Logout" below if you are ready to end your current
              session.
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                type="button"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
