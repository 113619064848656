import React from "react";

function ProjectForm(props) {
  return (
    <div className="d-sm-flex flex-column align-items-center justify-content-between mb-4">
      <h1 className="h3 mb-0 text-gray-800">New Profile</h1>
      <form onSubmit={props.handleSubmit}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <input type="text" className="form-control" id="description" name="description" />
        </div>
        <div className="mb-3">
          <label htmlFor="media" className="form-label">
            Media
          </label>
          <input
            type="file"
            className="form-control"
            id="media"
            name="media"
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  );
}

export default ProjectForm;
