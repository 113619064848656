export const SERVER_ADDRESS = "https://server.islamiccare.com.au";
// export const SERVER_ADDRESS = "http://localhost:7070";
//export const SERVER_ADDRESS = "http://43.205.210.43:7070";

export const fetchData = async (method, url, formData) => {
  const options = {};
  switch (method) {
    case "get":
      options.method = "GET";
      break;
    case "delete":
      options.method = "DELETE";
      options.credentials = "include";
      break;
    case "post":
      options.method = "POST";
      options.credentials = "include";
      options.body = formData;
      break;

    default:
      break;
  }
  const res = await fetch(`${SERVER_ADDRESS}${url}`, options);
  const { data } = await res.json();
  return data;
};

export const fetchJsonData = async (url, formData) => {
  const options = {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: formData,
    credentials: "include",
  };
  const res = await fetch(`${SERVER_ADDRESS}${url}`, options);
  const { data, error, user } = await res.json();
  return { data, error, user };
};
