import React, { useEffect, useState } from "react";
import HijriMonthForm from "../components/HijriMonthForm";
import { fetchData, fetchJsonData } from "../utils";

function HijriMonths(props) {
  const [data, setData] = useState([]);
  const [addNew, setAddNew] = useState(false);

  const handleDelete = async (id) => {
    const newData = await fetchData("delete", `/hijrimonths/${id}`);
    setData(newData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.target.blur();
    const formData = JSON.stringify({
      month: e.target.month.value,
      year: e.target.year.value,
      is29Month: e.target.is29Month.checked,
    });

    const { data: newData } = await fetchJsonData("/hijrimonths", formData);
    setData(newData);
    setAddNew(false);
  };

  useEffect(() => {
    (async () => {
      const newData = await fetchData("get", "/hijrimonths");
      setData(newData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {addNew && (
        <>
          <HijriMonthForm handleSubmit={handleSubmit} />
        </>
      )}
      {!addNew && (
        <>
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Hijri Months</h1>
            <button className="btn btn-info" onClick={() => setAddNew(true)}>
              Add New
            </button>
          </div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Month</th>
                <th scope="col">Year</th>
                <th scope="col">is 29 Month?</th>
              </tr>
            </thead>
            <tbody>
              {data.map((d, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{d.month}</td>
                  <td>{d.year}</td>
                  <td>{d.is29Month + ""}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(d._id)}
                    >
                      <i className="fa fas-trash"></i> Detete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default HijriMonths;
