import React, { useEffect, useState } from "react";
import NewsForm from "../components/NewsForm";
import { fetchData } from '../utils';

function News(props) {
  const [data, setData] = useState([]);
  const [addNew, setAddNew] = useState(false);

  const handleDelete = async (id) => {
    const newData = await fetchData("delete", `/news/${id}`)
    setData(newData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.target.blur();
    const formData = new FormData();
    formData.append("heading", e.target.heading.value);
    formData.append("description", e.target.description.value);
    formData.append("address", e.target.address.value);
    const st = e.target.startTime.value.split(":")
    const et = e.target.endTime.value.split(":")
    const startTime = new Date(e.target.date.value).getTime() + st[0] * 60 * 60 * 1000 + st[1] * 60 * 1000
    const endTime = new Date(e.target.date.value).getTime() + et[0] * 60 * 60 * 1000 + et[1] * 60 * 1000
    formData.append("startTime", new Date(startTime));
    formData.append("endTime", new Date(endTime));
    Object.keys(e.target.media.files).forEach(k=>{
      formData.append("media", e.target.media.files[k])
    })
  
    const newData = await fetchData("post", "/news", formData)
    setData(newData);
    setAddNew(false);
  };

  useEffect(() => {
    (async () => {
      const newData = await fetchData("get", "/news")
      setData(newData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {addNew && (
        <>
          <NewsForm handleSubmit={handleSubmit} />
        </>
      )}
      {!addNew && (
        <>
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">News</h1>
            <button className="btn btn-info" onClick={() => setAddNew(true)}>
              Add New
            </button>
          </div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Heading</th>
                <th scope="col">Description</th>
                <th scope="col">Address</th>
                <th scope="col">Start Time</th>
                <th scope="col">End Time</th>
              </tr>
            </thead>
            <tbody>
              {data.map((d, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{d.heading}</td>
                  <td>{d.description}</td>
                  <td>{d.address}</td>
                  <td>{d.startTime}</td>
                  <td>{d.endTime}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(d._id)}
                    >
                      <i className="fa fas-trash"></i> Detete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default News;
