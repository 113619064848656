import React from "react";

function NewsForm(props) {
  const d = new Date().toISOString()
  const todayDate = d.substring(0,10)
  const todayTime = d.substring(11,16)
  return (
    <div className="d-sm-flex flex-column align-items-center justify-content-between mb-4">
      <h1 className="h3 mb-0 text-gray-800">New News</h1>
      <form onSubmit={props.handleSubmit}>
        <div className="mb-3">
          <label htmlFor="heading" className="form-label">
            Heading
          </label>
          <input
            type="text"
            className="form-control"
            id="heading"
            name="heading"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <input
            type="text"
            className="form-control"
            id="description"
            name="description"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="address" className="form-label">
            Address
          </label>
          <input
            type="text"
            className="form-control"
            id="address"
            name="address"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="date" className="form-label">
            Date
          </label>
          <input type="date" className="form-control" id="date" name="date" defaultValue={todayDate} />
        </div>
        <div className="mb-3">
          <label htmlFor="startTime" className="form-label">
            Start Time
          </label>
          <input
            type="time"
            className="form-control"
            id="startTime"
            name="startTime"
            defaultValue={todayTime}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="endTime" className="form-label">
            End Time
          </label>
          <input
            type="time"
            className="form-control"
            id="endTime"
            name="endTime"
            defaultValue={todayTime}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="media" className="form-label">
            Media
          </label>
          <input
            type="file"
            className="form-control"
            id="media"
            name="media"
            accept=".pdf"
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  );
}

export default NewsForm;
