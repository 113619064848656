import React, { useEffect, useState } from "react";
import ProjectForm from "../components/ProjectForm";
import { fetchData } from '../utils';

function Projects(props) {
  const [data, setData] = useState([]);
  const [addNew, setAddNew] = useState(false);

  const handleDelete = async (id) => {
    const newData = await fetchData("delete", `/projects/${id}`)
    setData(newData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.target.blur();
    const formData = new FormData();
    formData.append("name", e.target.name.value);
    formData.append("description", e.target.description.value);
    Object.keys(e.target.media.files).forEach(k=>{
      formData.append("media", e.target.media.files[k])
    })

    const newData = await fetchData("post", "/projects", formData)
    setData(newData);
    setAddNew(false);
  };

  useEffect(() => {
    (async () => {
      const newData = await fetchData("get", "/projects")
      setData(newData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {addNew && (
        <>
          <ProjectForm handleSubmit={handleSubmit} />
        </>
      )}
      {!addNew && (
        <>
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Projects</h1>
            <button className="btn btn-info" onClick={() => setAddNew(true)}>
              Add New
            </button>
          </div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              {data.map((d, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{d.name}</td>
                  <td>{d.description}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(d._id)}
                    >
                      <i className="fa fas-trash"></i> Detete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default Projects;
