import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchJsonData } from '../utils';

const Register = (props) => {
  const navigate = useNavigate();
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = JSON.stringify({
      name: e.target.name.value,
      email: e.target.email.value,
      password: e.target.password.value,
      confirmPassword: e.target.confirmPassword.value,
    })
    const { error } = await fetchJsonData("/users/register", formData);

    if (error) {      
      document.querySelector("#messageBox").classList.remove("d-none");
      document.getElementById("message").innerHTML = error;
    } else {
      document.querySelector("#messageBox").classList.add("d-none");
      navigate("/")
    }
  };

  return (
    <div className="container d-sm-flex justify-content-center">
      <form onSubmit={submitHandler} className="w-40">
        <div className="mt-5">
          <h2>Create Account</h2>
        </div>
        <div className={`mb-3 alert alert-danger d-none`} id="messageBox">
          <span id="message"></span>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="name">
            Name
          </label>
          <input
            className="form-control"
            type="text"
            id="name"
            name="name"
            placeholder="Enter name"
            required
          ></input>
        </div>

        <div className="mb-3">
          <label className="form-label" htmlFor="email">
            Email address
          </label>
          <input
            className="form-control"
            type="email"
            id="email"
            name="email"
            placeholder="Enter email"
            required
          ></input>
        </div>

        <div className="mb-3">
          <label className="form-label" htmlFor="password">
            Password
          </label>
          <input
            className="form-control"
            type="password"
            id="password"
            name="password"
            placeholder="Enter password"
            required
          ></input>
        </div>

        <div className="mb-3">
          <label className="form-label" htmlFor="confirmPassword">
            Confirm Password
          </label>
          <input
            className="form-control"
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Enter confirm password"
            required
          ></input>
        </div>

        <div className="mb-3">
          <button
            className="btn btn-primary w-100"
            type="submit"
            onClick={(e) => e.target.blur()}
          >
            Register
          </button>
        </div>

        <div className="mb-3">
          <div>
            Already have an account? <Link to={`/`}>Sign-In</Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Register;
